import React, { Component } from 'react';

function Pagination({current,nbItems,itemsPerPage}) {

    const nbPages = Math.ceil(nbItems / itemsPerPage);
    var pages = [];
    for (let index = 0; index < nbPages; index++) {
        pages.push(<li className={`page-item ${((current==(index+1)) ? 'disabled' : '')}`}><a class="page-link" href="#">{index + 1}</a></li>);      
    }

    console.log(pages);
    console.log(nbPages);


    return (
        <nav>
            <ul class="pagination justify-content-center mt-3">
                <li className={`page-item ${((current==1) ? 'disabled' : '')}`}>
                    <a class="page-link" href="#" tabindex="-1"><span className="pe-7s-angle-left pe-2x"></span> </a>
                </li>
                {pages.map(p => p)}
                <li className={`page-item ${((current==nbPages) ? 'disabled' : '')}`}>
                    <a className="page-link" href="#"> <span className="pe-7s-angle-right pe-2x"></span> </a>
                </li>
            </ul>
        </nav>
    );

}


export default Pagination;