import React from 'react';
import Typist from 'react-typist';

class Hero extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <section class="jumbotron-two">

                <div class="container">

                    <div class="row align-items-center">
                        <div class="col-12 col-md-5">
                            <h1 class="display-5">Maintenez votre CMDB à jour automatiquement et faites-en enfin la base de votre cybersécurité !</h1>
                            <p class="text-muted mb-3">Ayez une vision exhaustive de vos assets IT, et découvrez ceux non managés et mal protégés. Réduisez votre surface d’attaque en détectant et en éliminant les failles latentes.</p>
                            <p>
                                <a href="#signup" class="btn btn-xl btn-primary">Demandez une démo</a>
                            </p>

                        </div>
                        <div class="col-12 col-md-7 my-3 my-md-lg">

                            <div class="cast-shadow" data-video-id="rm5sdAYCqqc" data-params="modestbranding=1&amp;showinfo=0&amp;controls=1&amp;vq=hd720">
                                {/*<img src="images/screen2.jpg" alt="image" class="img-fluid">*/}
                                <img src="images/dashboard_screenshots1.png" alt="image" class="img-fluid"/>
                                
                            </div>
                        </div>
                    </div>

                </div>

            </section> 
        );
    }
}

export default Hero;