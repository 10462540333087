import axios from 'axios';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const React = require('react');

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            email:'',
            phone:'',
            formSent:false,
            isLoading:false
        }

    }

    handleChange = (event) => {
        let state = {...this.state};
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const data = {...this.state};

        this.setState({
            isLoading:true
        })

        axios.post(`https://api.ynspecta.com/api/triigger-contact`, data)
            .then(res => {
                this.setState({
                    isLoading:false,
                    formSent:true
                });
            })
    }

    render() {
        return (
        <div class="section" id="contact">
            {
                !(this.state.formSent|| this.state.isLoading) &&
            
                <div class="container">
                    <div class="section-title text-center">
                        <h3>Contactez-nous</h3>
                        <p>pour découvrir comment notre solution peut vous aider</p>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col col-md-5">
                            <form onSubmit={this.handleSubmit}>
                                <div class="form-group">
                                    <input type="text" name="name" class="form-control" placeholder="Nom" value={this.state.name} onChange={this.handleChange} required/>
                                </div>
                                <div class="form-group">
                                    <input type="email" name="email" class="form-control" placeholder="Email" value={this.state.email} onChange={this.handleChange} required/>
                                </div>
                                <div class="form-group">
                                    <input type="tel"   name="phone" class="form-control" placeholder="0612345678" value={this.state.phone} onChange={this.handleChange} required pattern="0[0-9]{9}"/>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-xl btn-block btn-primary" >Demander une demo</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            }
            {
                this.state.isLoading &&
            
                <div class="container">
                    <div class="section-title text-center">
                    <Loader
                        type="Puff"
                        color="#7642FF"
                        height={100}
                        width={100}
                        timeout={5000} //3 secs
                        />
                    </div>
                </div>
            }
            {
                this.state.formSent &&
            
                <div class="container">
                    <div class="section-title text-center">
                        <h3>Vos demande a bien été prise en compte!</h3>
                        <p>Nous vous recontactons dans les plus brefs délais.</p>
                    </div>
                </div>
            }
        </div>
        );
    }
}

export default ContactForm;