import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

import HomePage from './pages/home.page';
import BlogPage from './pages/blog.page';
import BlogPostPage from './pages/blogpost.page';
import CasePage from './pages/case.page';


function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage} /> 
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={BlogPage} />
                    <Route path={`${process.env.PUBLIC_URL}/blog/:id`} component={BlogPostPage} />
                    <Route path={`${process.env.PUBLIC_URL}/cases/:id`} component={CasePage} />
                </Switch>
            </Router>
        </>
    );
}

export default App;
