import React, { Component } from 'react';
import Hero from '../components/hero/hero';
import ContactForm from '../components/contactform/contactform';
import NavBar from '../components/navbar/navbar.component';


class HomePage extends Component {
    render() {
        return (
            <>
                <header id="home">

                    {/* navbar */}
                    <NavBar path={this.props.match.path}/>
                    {/* end navbar */}

                    {/* hero */}
                    <Hero />
                    {/* end hero */}


                    <div class="bg-shape"></div>
                    <div class="bg-circle"></div>

                </header>

                <div class="section bg-light pt-lg">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="media-body text-center">
                                    <h5>
                                        L’inventaire de vos assets IT est le point de départ de votre politique de sécurité.
                </h5>
                                    <h5>
                                        Renforcer la protection de votre système d’informations grâce à
                </h5>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-12">
                                <div class="media mb-5">
                                    <div class="media-body text-center">
                                        <h5 class="mt-0"></h5>Une vision précise du matériel et des applicatifs de votre parc
                    <h5 class="mt-0"></h5>La détection des problèmes de couverture par les solutions IT
                    <h5 class="mt-0"></h5>La vérification exhaustive de l’état de mise à jour
                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="section py-lg" id="why">
                    <div class="container">

                        <div class="row">
                            <div class="col-md-6 col-lg-4">
                                <div class="media">

                                    {/* // end .di */}
                                    <div class="media-body text-center">
                                        <div class="color-icon mb-3"> <i class="pe-7s-server pe-3x"></i> </div>
                    Collecte de l’ensemble des données relatives à vos assets IT directement depuis vos sources.
                    <h5 class="mt-0">
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="media">
                                    {/* // end .di */}
                                    <div class="media-body text-center">
                                        <div class="color-icon mb-3"> <i class="pe-7s-search pe-3x"></i> </div>
                                        <h5 class="mt-0"></h5> Remontée des lacunes de sécurité en temps réel grâce à l’automatisation du traitement de la donnée.


        </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="media">
                                    {/* // end .di */}
                                    <div class="media-body text-center">
                                        <div class="color-icon mb-3"> <i class="pe-7s-refresh-2 pe-3x"></i> </div>
                                        <h5 class="mt-0"></h5>Emission d’alertes en cas de détection d’anomalies et mise en place d’actions correctives.

        </div>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-4">
                                <div class="media">

                                    {/* // end .di */}
                                    <div class="media-body text-center">
                                        <div class="color-icon mb-3"> </div>

                                        <h5 class="mt-0">
                                        </h5> Augmentez la visibilité pour savoir ce qu’il y a vraiment sur votre parc : ordinateurs, applicatifs, serveurs , cloud, mobiles, tablettes, IOT.
        </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="media">
                                    {/* // end .di */}
                                    <div class="media-body text-center">
                                        <div class="color-icon mb-3"> </div>
                                        <h5 class="mt-0"></h5> Identifiez rapidement les problèmes de couverture & détectez les matériels inconnus.
        </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <div class="media">
                                    {/* // end .di */}
                                    <div class="media-body text-center">
                                        <div class="color-icon mb-3"></div>
                                        <h5 class="mt-0"></h5>Réduisez le temps de réaction à incidents de sécurité et éliminez les surfaces d’attaque.
        </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="section bg-light" id="features">

                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-sm-8">
                                <div class="browser-window limit-height my-5 mr-0 mr-sm-5">
                                    <div class="content">
                                        <img src="images/connectors_logos.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="media">
                                    <div class="media-body">
                                        <div class="media-icon mb-3"> <i class="pe-7s-share pe-3x"></i> </div>
                                        <h3 class="mt-0">Connexion aux solutions IT et de sécurité</h3>
                                        <p> Triigger est une solution « agentless ». Elle se connecte simplement et rapidement aux différents outils qui contiennent de la donnée sur les assets IT : CMDB, ITSM, MDM, antivirus, scanner de vulnérabilités, firewall…</p>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <div class="row align-items-center mt-5">

                            <div class="col-sm-4">
                                <div class="media">
                                    <div class="media-body">
                                        <div class="media-icon mb-3"> <i class="pe-7s-search pe-3x"></i> </div>
                                        <h3 class="mt-0">Détection en continue </h3>
                                        <p>Triigger utilise les technologies de Machine Learning pour identifier les assets IT de votre réseau qui ne sont pas conformes. Vous pouvez également personnaliser les paramètres en fonction des spécificités de votre organisation.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="col-sm-8">
                                <img src="images/workflow_screenshots1.png" alt="image" class="img-fluid cast-shadow my-5" />
                            </div>


                        </div>


                        <div class="row align-items-center">
                            <div class="col-sm-8">
                                <div class="browser-window limit-height my-5 mr-0 mr-sm-5">
                                    <div class="content">
                                        <img src="images/devices_screenshot.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="media">
                                    <div class="media-body">
                                        <div class="media-icon mb-3"> <i class="pe-7s-info pe-3x"></i> </div>
                                        <h3 class="mt-0">Alertes et corrections</h3>
                                        <p> En cas de détection d’anomalies, TRIIGGER vous notifie en temps réel (par e-mail, ticket) et fournit la liste des assets concernés. Vos équipes peuvent décider des mesures correctives pour maintenir le niveau de protection du système d’information.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>



                </div>
                <ContactForm />
            </>
        );
    }
}

export default HomePage;