import React, { Component } from 'react';
import { Link } from 'react-router-dom';

function NavBarBlog(props) {
    return (
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container">
                <a class="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                    <h3 class="gradient-mask">triigger</h3>
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#site-nav" aria-controls="site-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="site-nav">
                    <ul class="navbar-nav text-sm-left ml-auto">
                        <li class="nav-item">
                            <Link to={`${process.env.PUBLIC_URL}/`}>← Retour à l'accueil</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );

}

export default NavBarBlog;