import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import posts from './post.json';
import ReactHtmlParser from 'react-html-parser';

import NavBarBlog from '../components/navbarblog/navbarblog.component';
import Pagination from '../components/pagination/pagination.component';

class BlogPage extends Component {

    constructor(props) {
        super(props);
        this.state =  {
            current:1,
            itemsPerPage:9,
            nbItems:posts.length
        };
    }

    render() {
        return (
            <>
                <header id="">

                    {/* navbar */}
                    <NavBarBlog />
                    {/* end navbar */}

                    <section class="jumbotron sub-page text-center d-flex align-items-center">
                        <div class="container">
                            <h1 class="display-4">Notre Blog</h1>
                            <p class="lead my-4" data-wow-delay="0.5s">Ici nous parlons de nous : Mises à jour produit, Marketing, Commercial et notre aventure Startup.</p>
                        </div>
                    </section>
                </header>
                <div class="section my-lg" id="blog">
                    <div class="container">
                        <div class="row">
                            {
                                posts.map((post,index) => {
                                    return(
                                    <div class="col-md-6 col-lg-4">
                                        <article class="post text-center mb-5">
                                            <div class="post_media_wrapper">
                                                <a class="title" href="blog-single.html">
                                                    <img class="img-fluid" alt="thumbnail" src="images/blog/blog-1.jpg" /></a>
                                            </div>
                                            <div class="entry-content clearfix">
                                                <Link to={`${process.env.PUBLIC_URL}/blog/${index+1}`}>
                                                    <h4 class="post_title">{ReactHtmlParser(post.title)} </h4>
                                                </Link>
                                                <div class="post-meta text-muted mb-3">
                                                    <small class="author"> by <a href="#" class="link-muted">John Mickey </a></small>
                                                    <small class="time"> on <a href="#" class="link-muted">July 10 </a> </small>
                                                </div>
                                                <p> {ReactHtmlParser(post.summary)} </p>
                                            </div>
                                            {/* .entry-content */}
                                            <a href="blog-single.html">Lire →</a>
                                        </article>
                                    </div>
                                )})
                            }
                        </div>
                        {/* // end .row */}

                        <Pagination 
                            current={this.state.current} 
                            itemsPerPage={this.state.itemsPerPage}
                            nbItems={this.state.nbItems}/>
                    </div>
                    {/* // end .container */}
                </div>

            </>
        );
    }
}

export default BlogPage;