import React, { Component } from 'react';
import { Link } from 'react-router-dom';

function NavBar(props) {
    return (
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container">
                <a class="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                    <h3 class="gradient-mask">triigger</h3>
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#site-nav" aria-controls="site-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="site-nav">
                    <ul class="navbar-nav text-sm-left ml-auto">
                        {
                            (props.path == `${process.env.PUBLIC_URL}/`)
                            &&  <li class="nav-item">
                                    <a class="nav-link" href="#features">Features</a>
                                </li>
                        }

                        {/*<li class="nav-item dropdown">
                            <a class="nav-link" href="#" data-toggle="dropdown">Cas d'usage <span class="pe-2x pe-7s-angle-down"></span>  </a>
                            <div class="dropdown-menu">
                                <Link className='dropdown-item' to={`${process.env.PUBLIC_URL}/cases/2`}>Maintien à jour automatique de la CMDB</Link>
                                <a class="dropdown-item" href="#">Détection de materiels inconnus</a>
                                <a class="dropdown-item" href="#">Couverture du scanner de vulnerabilités</a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <Link className="nav-link" to={`${process.env.PUBLIC_URL}/blog`}> Blog</Link>
                    </li>*/}
                        {
                            (props.path == `${process.env.PUBLIC_URL}/`)
                            &&  <li class="nav-item">
                                    <a class="nav-link" href="#contact">Contact</a>
                                </li>
                        }
                        


                    </ul>

                </div>
            </div>
        </nav>
    );

}

export default NavBar;