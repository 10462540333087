import React, { Component } from 'react';
import NavBar from '../components/navbar/navbar.component';
import ReactHtmlParser from 'react-html-parser';
import cases from './cases.json';


class CasePage extends Component {


    render() {

        var c = cases[this.props.match.params.id - 1];

        return (
            <>
                <header id="home">

                    {/* navbar */}
                    <NavBar />
                    {/* end navbar */}
                    <section class="jumbotron sub-page text-center d-flex align-items-center">
                        <div class="container">
                            <h1 class="display-4">{c.title}</h1>
                            <p class="lead my-4">by John Mickey on July 10 </p>
                        </div>
                        {/* // end .scroll-down */}
                    </section>
                    {/* // end hero */}

                </header>
                <div class="section my-4" id="blog">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="entry-content">
                                    {
                                        (ReactHtmlParser(c.content))
                                    }
                                </div>
                            </div>
                            {/* // end .col-md-8 */}</div>
                    </div>
                    {/* // end .row justify-content-center */}
                </div>
            </>


        );
    }
}

export default CasePage;