import React, { Component } from 'react';
import NavBarBlog from '../components/navbarblog/navbarblog.component';
import posts from './post.json';
import ReactHtmlParser from 'react-html-parser';

class BlogPostPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            text : "<p>Hello my world</p>",
        };
    }

    componentDidMount = () => {

    }



    render() {
        const post = posts[this.props.match.params.id - 1];
        return (
            <>
                <header>
                    <NavBarBlog />
                    {/* hero */}
                    <section class="jumbotron sub-page text-center d-flex align-items-center">
                        <div class="container">
                            <h1 class="display-4">{ReactHtmlParser(post.title)}</h1>
                            <p class="lead my-4">by John Mickey on July 10 </p>
                        </div>
                        {/* // end .scroll-down */}
                    </section>
                    {/* // end hero */}
                </header>



                <div class="section my-4" id="blog">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="entry-content">
                                    {
                                        (ReactHtmlParser(post.content))
                                    }
                                </div>
                            </div>
                            {/* // end .col-md-8 */}</div>
                    </div>
                    {/* // end .row justify-content-center */}</div>
                <div class="media author-box">
                    <img class="d-flex mr-3 rounded-circle" src={`${process.env.PUBLIC_URL}/images/blog/thumb3.jpg`} alt="Author" />
                    <div class="media-body">
                        <h5 class="mt-0">Linda Carter</h5> Project Lead at Startly. I swim, cycle, and run a lot. When I’m not doing all those, I love to read and try new things.
                    <br />
                        <div class="mt-2"> <a href="#">Follow me on Twitter →</a> </div>
                    </div>
                </div>

            </>
        );
    }
}

export default BlogPostPage;